<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          :disabled="value.id === demoUserId"
        >
          <v-card-title class="d-flex justify-space-between">
            {{$t('user.profile')}}
            <div class="d-flex flex-row align-center">
              <BaseBtn
                @click="isDialogReset = true"
                icon="mdi-account-key"
                text
                color-icon="orange"
              />
              <ChangePassword
                @input="isDialogReset = !isDialogReset"
                :value="isDialogReset"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="detail.username"
              dense
              outlined
              :error-messages="checkErrors('detail.username')"
              :label="$t('user.username')"
            >
            </v-text-field>
            <BaseMaskedInput
              v-model="detail.phone"
              color="secondary"
              :label="$t('user.phone')"
              dense
              outlined
              :placeholder="'+7 (9**) *** ** **'"
              :error-messages="checkErrors('detail.phone')"
              :input-mask="masks.phoneMask.input"
              :output-mask="masks.phoneMask.output"
            />
            <v-text-field
              v-model="detail.email"
              dense
              outlined
              :error-messages="checkErrors('detail.email')"
              :label="$t('user.email')"
            >
            </v-text-field>
          </v-card-text>
          <v-card-title>{{$t('user.info')}}</v-card-title>
          <v-card-text class="d-flex">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{$t('organization.name')}}</v-list-item-title>
                  <!--todo:имя организации с сервера-->
                  <v-list-item-subtitle>{{detail.organization.name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!--<v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{$t('base.created_at')}}</v-list-item-title>
                  &lt;!&ndash;/todo:дата создания с сервера/&ndash;&gt;
                  <v-list-item-subtitle>{{}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>-->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{$t('organization.role')}}</v-list-item-title>
                  <v-list-item-subtitle>{{roleListByName(detail.role)}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-text>
            <RoleSelect
              v-model="currentRole"
              :role-list="detail.role"
              :clearable="false"
              :label="$t('organization.role')"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChangePassword from '@/components/views/account/profile/detail/ChangePassword'
import validationErrors from '@/components/mixins/validationErrors'
import * as masks from '@/config/masks'
import BaseMaskedInput from '@/components/base/forms/BaseMaskedInput'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import BaseBtn from '@/components/base/UI/BaseBtn'
import RoleSelect from '@/components/views/account/user/detail/role/RoleSelect'
import user from '@/components/mixins/user'

export default {
  mixins: [validationErrors, serviceDataComputeds, user],
  name: 'DetailView',
  components: { ChangePassword, BaseMaskedInput, BaseBtn, RoleSelect },
  props: {
    value: Object
  },
  data () {
    return {
      isDownloading: false,
      masks: masks,
      detail: {},
      isDialogReset: false,
      demoUserId: 95
    }
  },
  inject: ['$v'],
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
