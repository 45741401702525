<template>
  <BaseDetail
    @save="handleSave"
    @close="handleClose"
  >
    <template #header>
      <BaseHeader
        @save="handleSave"
        @close="handleClose"
        :delete-btn="false"
      />
    </template>
    <template #content>
      <DetailView
        v-if="detail"
        v-model="detail"
      />
    </template>
  </BaseDetail>
</template>

<script>

import BaseDetail from '@/components/base/BaseDetail'
import DetailView from '@/components/views/account/profile/detail/DetailView'
import validationErrors from '@/components/mixins/validationErrors'
import { required, email, minLength } from 'vuelidate/lib/validators'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'

export default {
  mixins: [validationErrors],
  name: 'Detail',
  components: { BaseHeader, DetailView, BaseDetail },
  data () {
    return {
      detail: this.$store.getters['auth/getProfile']
    }
  },
  validations: {
    detail: {
      username: {
        required
      },
      phone: {
        required,
        minLength: minLength(11)
      },
      email: {
        required,
        email,
        isUnique (value) {
          let result = true
          if (this.$v.detail.email.$dirty) {
            result = this.$store.dispatch('auth/isUnique', {
              data: {
                email: value
              }
            })
          }
          return result
        }
      }
    }
  },
  methods: {
    async handleSave () {
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.detail.$touch()
        if (await this.checkPending() && !this.$v.detail.$anyError) {
          await this.$store.dispatch('auth/saveProfile', {
            data: this.detail
          })
        }
        this.isSaving = false
      }
      return true
    },
    handleClose () {
      this.$router.back()
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  }
}
</script>

<style scoped>

</style>
