<template>
  <BaseDialog
    :label="$t('user.changePassword')"
    is-confirm-btn
    is-cancel-btn
    @confirm="handleChangePassword"
    @input="$emit('input')"
    :value="value"
    :text-cancel="$t('base.cancel')"
    :text-confirm="$t('base.save')"
    :max-width="500"
    :is-loading="isSaving"
  >
    <template #content>
      <v-card-text>
        <v-text-field
          v-model="oldPassword"
          dense
          outlined
          :label="$t('user.oldPassword')"
          type="password"
          prepend-icon="mdi-account-key"
          :error-messages="checkErrors('oldPassword')"
        >
        </v-text-field>
        <v-divider class="ma-2"></v-divider>
        <v-text-field
          v-model="newPassword"
          dense
          outlined
          :label="$t('user.newPassword')"
          type="password"
          :error-messages="checkErrors('newPassword')"
        >
        </v-text-field>
        <v-text-field
          v-model="repeatNewPassword"
          dense
          outlined
          :label="$t('user.repeatNewPassword')"
          type="password"
          :error-messages="checkErrors('repeatNewPassword')"
        >
        </v-text-field>
      </v-card-text>
    </template>
  </BaseDialog>
</template>

<script>
import validationErrors from '@/components/mixins/validationErrors'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  components: { BaseDialog },
  mixins: [validationErrors],
  name: 'ChangePassword',
  props: {
    value: Boolean
  },
  data () {
    return {
      dialog: false,
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      isSaving: false
    }
  },
  validations: {
    oldPassword: {
      required
    },
    newPassword: {
      required,
      minLength: minLength(6)
    },
    repeatNewPassword: {
      required,
      sameAsPassword: sameAs(function () {
        return this.newPassword
      })
    }
  },
  methods: {
    async handleChangePassword () {
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.$touch()
        if (this.$v.$anyDirty && !this.$v.$anyError) {
          await this.$store.dispatch('auth/changePassword', {
            data: {
              password: this.oldPassword,
              newPassword: this.newPassword,
              repeatPassword: this.repeatNewPassword
            }
          })
          this.$v.$reset()
        }
        this.isSaving = false
      }
      this.$emit('cancel')
      return true
    }
  }
}

</script>

<style scoped>

</style>
